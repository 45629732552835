import {
  PrescriptionListItem,
  PrescriptionListItemJson,
} from "../../types/prescription";

export interface PatientFinancialDataJson {
  id: number;
  household_size: number;
  estimated_income: number;
  difficulty_affording_expenses: boolean;
  meds_not_covered: boolean;
}

export class PatientFinancialData {
  constructor(
    public pk: number,
    public householdSize: number,
    public estimatedIncome: number,
    public difficultyAffordingExpenses: boolean,
    public medsNotCovered: boolean
  ) {}

  static fromJson(data: PatientFinancialDataJson): PatientFinancialData {
    return new PatientFinancialData(
      data.id,
      data.household_size,
      data.estimated_income,
      data.difficulty_affording_expenses,
      data.meds_not_covered
    );
  }
}

export interface PatientListItemJson {
  id: number;
  full_name: string;
  phone_number: string;
  email: string;
  member_id: string;
  group_id: string;
  date_of_birth: string;
  display_address: string;
}

export class PatientListItem {
  constructor(
    public id: number,
    public fullName: string,
    public phoneNumber: string,
    public email: string,
    public dateOfBirth: Date,
    public displayAddress: string,
    public memberId?: string,
    public groupId?: string
  ) {}

  static fromJson(data: PatientListItemJson): PatientListItem {
    return new PatientListItem(
      data.id,
      data.full_name,
      data.phone_number,
      data.email,
      new Date(data.date_of_birth),
      data.display_address,
      data.member_id,
      data.group_id
    );
  }
}

export interface PatientJson {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  display_address: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  email: string;
  phone_number: string;
  allergies: string;
  no_known_drug_allergies: boolean;
  date_of_birth: string;
  weight: number;
  height: number;
  notes: string;
  icd_10_codes: string;
  require_delivery_signature: boolean;
  prefer_email_communication: boolean;
  member_id: string;
  group_id: string;
  financial_data: PatientFinancialDataJson;
  prescriptions: PrescriptionListItemJson[];
}

export class Patient {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public displayAddress: string,
    public streetAddress: string,
    public city: string,
    public state: string,
    public zipCode: string,
    public email: string,
    public phoneNumber: string,
    public allergies: string,
    public noKnownDrugAllergies: boolean,
    public dateOfBirth: Date,
    public weight: number,
    public height: number,
    public notes: string,
    public icd10Codes: string,
    public requireDeliverySignature: boolean,
    public preferEmailCommunication: boolean,
    public memberId: string,
    public groupId: string,
    public financialData: PatientFinancialData | null,
    public prescriptions: PrescriptionListItem[]
  ) {}
  static fromJson(data: PatientJson): Patient {
    return new Patient(
      data.id,
      data.first_name,
      data.last_name,
      data.full_name,
      data.display_address,
      data.street_address,
      data.city,
      data.state,
      data.zip_code,
      data.email,
      data.phone_number,
      data.allergies,
      data.no_known_drug_allergies,
      new Date(data.date_of_birth),
      data.weight,
      data.height,
      data.notes,
      data.icd_10_codes,
      data.require_delivery_signature,
      data.prefer_email_communication,
      data.member_id,
      data.group_id,
      data.financial_data
        ? PatientFinancialData.fromJson(data.financial_data)
        : null,
      data.prescriptions.map(PrescriptionListItem.fromJson)
    );
  }
}
