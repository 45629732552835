import { useState } from "react";
import Button from "../../components/Button/Button";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import { ApiStatus } from "../../types/api_status";
import styles from "./ContactSupport.module.css";
import { submitSupportRequest } from "../../api/support_network_api";
import { useForm } from "react-hook-form";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../constants";
import ContactSupportSuccess from "./ContactSupportSuccess/ContactSupportSuccess";

interface IFormData {
  title: string;
  content: string;
}

export default function ConactSupport() {
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.INITIAL);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  const onSubmit = (data: IFormData) => {
    setApiStatus(ApiStatus.LOADING);
    return submitSupportRequest(data.title, data.content)
      .then(() => setApiStatus(ApiStatus.SUCCESS))
      .catch(() => setApiStatus(ApiStatus.FAILURE));
  };

  return (
    <DashboardPageBody title="Request a feature">
      {apiStatus === ApiStatus.SUCCESS ? (
        <ContactSupportSuccess
          onReset={() => {
            setApiStatus(ApiStatus.INITIAL);
            reset();
          }}
        ></ContactSupportSuccess>
      ) : (
        <>
          <p className={styles.subtitle}>
            At MediCircle, we are dedicated to ensuring seamless partner
            experiences. Submit requests or feedback using the form below:
          </p>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.titleInputWrap}>
              <input
                {...register("title", { required: true })}
                className={`${styles.supportInput} ${
                  errors.title ? styles.inputError : ""
                }`}
                placeholder="Briefly describe the issue..."
              />
              {errors.title && (
                <p className={styles.error}>{REQUIRED_FIELD_ERROR_MESSAGE}</p>
              )}
            </div>

            <textarea
              {...register("content")}
              className={styles.supportInput}
              placeholder="Enter additional details..."
            />

            {apiStatus === ApiStatus.FAILURE && (
              <p className={styles.error}>Unable to submit request</p>
            )}
            <div className={styles.buttonWrap}>
              <Button
                submit={true}
                pill={false}
                padding="12px 64px"
                loading={apiStatus === ApiStatus.LOADING}
              >
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
    </DashboardPageBody>
  );
}
