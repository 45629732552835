import styles from "./styles.module.css";

interface SecondaryButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
}

export default function SecondaryButton(props: SecondaryButtonProps) {
  return (
    <button
      className={`${styles.button} ${styles.secondary}`}
      onClick={props.onClick}
      type={props.type}
    >
      <span className={styles.content}>{props.children}</span>
    </button>
  );
}
