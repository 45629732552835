import { FormProvider, useForm } from "react-hook-form";
import Card from "../../../components/Card/Card";
import styles from "./PatientForm.module.css";

import { useState } from "react";

import Input from "../../../components/Input/Input";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import { addPatient } from "../../Patients2/api";
import { STATE_CODES } from "../../../constants";
import { Patient } from "../../Patients2/types";

interface PatientFormProps {
  onDone: (patient: Patient | null) => void;
}

interface IFormInput {
  firstName: string;
  lastName: string;
  allergies: string;
  noKnownDrugAllergies: boolean;
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  weight: number;
  height: number;
  icd10Codes: string;
  notes: string;
  preferredCommunication: string;
  requireSignature: boolean;
  memberId: string;
  groupId: string;
  // householdSize: number;
  // estimatedIncome: number;
  // medicationsUnaffordable: boolean;
  // medsNotCovered: boolean;
}

const sanitizeDateOfBirth = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

const getPrefersEmailFromBoolean = (value: string) => {
  return value === "email";
};

export default function PatientForm(props: PatientFormProps) {
  const [networkError, setNetworkError] = useState("");

  const methods = useForm<IFormInput>({
    mode: "onSubmit",
  });
  const errors = methods.formState.errors;

  const createPatient = (data: IFormInput, preferEmail: boolean) => {
    return addPatient(
      data.firstName,
      data.lastName,
      data.email,
      data.phone,
      data.streetAddress,
      data.city,
      data.state,
      data.zipCode,
      data.allergies,
      data.noKnownDrugAllergies,
      data.dateOfBirth,
      data.weight,
      data.height,
      data.notes,
      data.icd10Codes,
      preferEmail,
      data.requireSignature,
      data.memberId,
      data.groupId
    );
  };

  const onSubmit = (data: IFormInput) => {
    setNetworkError("");
    const preferEmail = getPrefersEmailFromBoolean(data.preferredCommunication);
    return createPatient(data, preferEmail)
      .then((p) => props.onDone(p))
      .catch(() => {
        setNetworkError("Unable to save patient data");
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <Card className={styles.card}>
          <div className={styles.patientForm}>
            <h3>Enter patient information</h3>
            <div className={styles.formSection}>
              <div className={styles.inputGrid}>
                <Input
                  id={"firstName"}
                  name={"firstName"}
                  label="First name"
                  autocomplete="off"
                  autoFocus={true}
                  registerOptions={{ required: true }}
                  hasError={!!errors.firstName}
                ></Input>
                <Input
                  id={"lastName"}
                  name={"lastName"}
                  label="Last name"
                  registerOptions={{ required: true }}
                  hasError={!!errors.lastName}
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"email"}
                  name={"email"}
                  label="Email"
                  registerOptions={{ required: true }}
                  hasError={!!errors.email}
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"phone"}
                  name={"phone"}
                  label="Phone"
                  registerOptions={{ required: true }}
                  hasError={!!errors.phone}
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"streetAddress"}
                  name={"streetAddress"}
                  label="Street address"
                  registerOptions={{ required: true }}
                  hasError={!!errors.streetAddress}
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"city"}
                  name={"city"}
                  label="City"
                  registerOptions={{ required: true }}
                  hasError={!!errors.city}
                ></Input>
                <select
                  className={styles.select}
                  id="state"
                  {...methods.register("state", { required: true })}
                >
                  <option value="">State</option>
                  {STATE_CODES.map((s) => (
                    <option value={s}>{s}</option>
                  ))}
                </select>
                <Input
                  id={"zip"}
                  name={"zipCode"}
                  label="Zip code"
                  registerOptions={{ required: true }}
                  hasError={!!errors.zipCode}
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"dateOfBirth"}
                  name={"dateOfBirth"}
                  type="date"
                  label="Date of birth"
                  max={sanitizeDateOfBirth(new Date())}
                  min={sanitizeDateOfBirth(new Date(Date.UTC(1900, 0, 1)))}
                  registerOptions={{
                    required: true,
                  }}
                  hasError={!!errors.dateOfBirth}
                ></Input>
                <Input
                  id={"memberId"}
                  name={"memberId"}
                  label="Member ID"
                ></Input>
                <Input id={"groupId"} name={"groupId"} label="Group ID"></Input>
                {networkError && (
                  <p className={styles.networkError}>{networkError}</p>
                )}
              </div>
              <div className={styles.inputGrid}>
                <Input
                  registerOptions={{ valueAsNumber: true }}
                  id={"weight"}
                  name={"weight"}
                  label="Weight (lbs)"
                  type="number"
                  min="0"
                ></Input>
                <Input
                  registerOptions={{ valueAsNumber: true }}
                  id={"height"}
                  name={"height"}
                  label="Height (in)"
                  type="number"
                  min="0"
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"allergies"}
                  name={"allergies"}
                  label="Allergies (optional)"
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"icd10Codes"}
                  name={"icd10Codes"}
                  label="ICD-10 codes (optional)"
                ></Input>
                <Input
                  className={styles.fullSpanInput}
                  id={"notes"}
                  name={"notes"}
                  label="Notes (optional)"
                ></Input>
                <p className={styles.communicationLabel}>
                  Preferred communication:
                </p>
                <div className={styles.communication}>
                  <div>
                    <input
                      {...methods.register("preferredCommunication")}
                      type="radio"
                      id="emailCommunication"
                      value={"email"}
                    />
                    <label htmlFor="emailCommunication">Email</label>
                  </div>
                  <div>
                    <input
                      {...methods.register("preferredCommunication")}
                      type="radio"
                      id="phoneCommunication"
                      value={"phone"}
                    />
                    <label htmlFor="phoneCommunication">Phone</label>
                  </div>
                </div>
                <div className={styles.fullSpanInput}>
                  <input
                    {...methods.register("requireSignature")}
                    className={styles.signatureCheckbox}
                    type="checkbox"
                    id="requireSignature"
                  ></input>
                  <label
                    className={styles.signatureLabel}
                    htmlFor="requireSignature"
                  >
                    Require signature upon delivery
                  </label>
                </div>
                <div className={styles.fullSpanInput}>
                  <input
                    {...methods.register("noKnownDrugAllergies")}
                    className={styles.signatureCheckbox}
                    type="checkbox"
                    id="noKnownDrugAllergies"
                  ></input>
                  <label
                    className={styles.signatureLabel}
                    htmlFor="noKnownDrugAllergies"
                  >
                    The patient has no known drug allergies
                  </label>
                  {errors.noKnownDrugAllergies && (
                    <p className={styles.error}>
                      Please acknowledge this patient has no known drug
                      allergies.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.buttonWrap}>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={() => props.onDone(null)}
              >
                Cancel
              </button>
              <PrimaryButton>Submit</PrimaryButton>
            </div>
          </div>
        </Card>
      </form>
    </FormProvider>
  );
}
