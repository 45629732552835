import { useEffect, useState } from "react";
import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { ApiStatus } from "../../../types/api_status";
import { Prescription2 } from "../../../types/prescription";
import { useNavigate, useParams } from "react-router";
import { cancelPrescription, getPrescription } from "../api";
import PrescriptionDetailBox from "./components/PrescriptionDetailBox";
import styles from "./styles.module.css";
import Link from "../../../assets/Link.svg";
import ConsentFormLinkPopup from "../../../components/ConsentFormLinkPopup/ConsentFormLinkPopup";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { Patient } from "../../Patients2/types";
import { getPatient } from "../../Patients2/api";
import SecondaryButton from "../../../components/Button2/SecondaryButton";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import Trash from "../../../assets/Trash.svg";
import DispenseListItem from "./components/DispenseListItem";

interface PrescriptionDetailFetchState {
  status: ApiStatus;
  prescription: Prescription2 | null;
}

interface PatientDetailFetchState {
  status: ApiStatus;
  patient: Patient | null;
}

export default function PrescriptionDetail() {
  const navigate = useNavigate();
  const notifySuccess = (text: string) => toast.success(text);

  const { prescriptionId } = useParams();
  const { patientId } = useParams();

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const [prescriptionDetailFetchState, setPrescriptionDetailFetchState] =
    useState<PrescriptionDetailFetchState>({
      status: ApiStatus.LOADING,
      prescription: null,
    });

  const [, setPrescriptionCancellationState] = useState<ApiStatus>(
    ApiStatus.INITIAL
  );

  const [patientDetailFetchState, setPatientDetailFetchState] =
    useState<PatientDetailFetchState>({
      status: ApiStatus.LOADING,
      patient: null,
    });

  const _cancelPrescription = () => {
    if (patientId === undefined || prescriptionId === undefined) {
      setPrescriptionCancellationState(ApiStatus.FAILURE);
      return;
    }
    return cancelPrescription(patientId, prescriptionId)
      .then(() => {
        notifySuccess("Canceled prescription");
        navigate(`/patients/${patientId}`);
      })
      .catch((e) => {
        hideGlobalOverlay();
        if (e.response.data) {
          toast.error(e.response.data[0]);
        } else {
          toast.error("Unable to cancel prescription");
        }
      });
  };

  useEffect(() => {
    const _getPatientDetails = () => {
      if (patientId === undefined) {
        setPatientDetailFetchState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      } else {
        getPatient(patientId)
          .then((result) => {
            setPatientDetailFetchState((prev) => ({
              ...prev,
              patient: result,
            }));
          })
          .catch((e) => {
            setPatientDetailFetchState((prev) => ({
              ...prev,
              status: ApiStatus.FAILURE,
            }));
          });
      }
    };
    const _getPrescriptionDetails = () => {
      if (prescriptionId === undefined || patientId === undefined) {
        setPrescriptionDetailFetchState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      } else {
        getPrescription(patientId, prescriptionId)
          .then((result) => {
            setPrescriptionDetailFetchState((prev) => ({
              ...prev,
              prescription: result,
            }));
          })
          .catch((e) => {
            setPrescriptionDetailFetchState((prev) => ({
              ...prev,
              status: ApiStatus.FAILURE,
            }));
          });
      }
    };

    _getPrescriptionDetails();
    _getPatientDetails();
  }, [patientId, prescriptionId]);

  return (
    <DashboardPageBody
      title="Prescription"
      backLink={`/patients/${patientId}`}
      headerSuffix={
        prescriptionDetailFetchState.prescription && (
          <div className={styles.actions}>
            <SecondaryButton
              onClick={() => {
                if (patientId) {
                  setGlobalOverlay(
                    <ConsentFormLinkPopup
                      patientId={patientId}
                      onDone={hideGlobalOverlay}
                    ></ConsentFormLinkPopup>
                  );
                }
              }}
            >
              <img
                src={Link}
                title="Get patient form link"
                alt="Patient link"
                className={styles.linkIcon}
              ></img>
              Consent form link
            </SecondaryButton>
            {/* <SecondaryButton onClick={() => {}}>
              <img src={Edit} alt="Trash" className={styles.linkIcon} />
              Edit prescription
            </SecondaryButton> */}
            <SecondaryButton
              onClick={() => {
                setGlobalOverlay(
                  <ConfirmationDialog
                    title="Confirm cancellation"
                    leftLabel="Back"
                    subtitle="Are you sure you want to cancel this prescription? Inventory will no longer be reserved for this prescription."
                    onCancel={hideGlobalOverlay}
                    isDestructive={true}
                    onConfirm={_cancelPrescription}
                  ></ConfirmationDialog>
                );
              }}
            >
              <img src={Trash} alt="Trash" className={styles.linkIcon} />
              Cancel prescription
            </SecondaryButton>
          </div>
        )
      }
    >
      <div>
        <PrescriptionDetailBox
          patient={patientDetailFetchState.patient}
          prescription={prescriptionDetailFetchState.prescription}
          status={prescriptionDetailFetchState.status}
        ></PrescriptionDetailBox>
        {prescriptionDetailFetchState.prescription && (
          <div className={styles.dispensesSection}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Dispense date</th>
                  <th>Status</th>
                  <th>Consent form</th>
                  <th>Tracking number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {prescriptionDetailFetchState.prescription.dispenses.length ===
                0 ? (
                  <tr>
                    <td colSpan={6} className={styles.dispensesEmptyState}>
                      No dispenses
                    </td>
                  </tr>
                ) : (
                  prescriptionDetailFetchState.prescription.dispenses.map(
                    (dispense) => (
                      <DispenseListItem
                        dispense={dispense}
                        patientId={patientId ?? ""}
                        prescriptionId={prescriptionId ?? ""}
                      ></DispenseListItem>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </DashboardPageBody>
  );
}
