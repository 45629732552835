import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { useForm } from "react-hook-form";
import styles from "./styles.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addPatient, getPatient, updatePatient } from "../api";
import { STATE_CODES } from "../../../constants";
import { useEffect } from "react";
import PrimaryButton from "../../../components/Button2/PrimaryButton";

interface FormInput {
  firstName: string;
  lastName: string;
  allergies: string;
  noKnownDrugAllergies: boolean;
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  weight: number;
  height: number;
  icd10Codes: string;
  notes: string;
  preferredCommunication: string;
  requireSignature: boolean;
  memberId: string;
  groupId: string;
}

export default function PatientForm() {
  const { patientId } = useParams<{ patientId: string }>();
  const navigate = useNavigate();
  const notifySuccess = (text: string) => toast.success(text);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInput>();

  useEffect(() => {
    if (patientId) {
      getPatient(patientId).then((patient) => {
        reset({
          firstName: patient.firstName,
          lastName: patient.lastName,
          email: patient.email,
          phone: patient.phoneNumber,
          streetAddress: patient.streetAddress,
          city: patient.city,
          state: patient.state,
          zipCode: patient.zipCode,
          allergies: patient.allergies,
          noKnownDrugAllergies: patient.noKnownDrugAllergies,
          dateOfBirth: patient.dateOfBirth.toISOString().substring(0, 10),
          weight: patient.weight,
          height: patient.height,
          notes: patient.notes,
          icd10Codes: patient.icd10Codes,
          preferredCommunication: patient.preferEmailCommunication
            ? "email"
            : "phone",
          requireSignature: patient.requireDeliverySignature,
          memberId: patient.memberId,
          groupId: patient.groupId,
        });
      });
    }
  }, [patientId, reset]);

  const onSubmit = (data: FormInput) => {
    if (patientId) {
      return updatePatient(
        patientId,
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.streetAddress,
        data.city,
        data.state,
        data.zipCode,
        data.allergies,
        data.noKnownDrugAllergies,
        data.dateOfBirth,
        data.weight,
        data.height,
        data.notes,
        data.icd10Codes,
        data.preferredCommunication === "email",
        data.requireSignature,
        data.memberId,
        data.groupId
      )
        .then((patient) => {
          notifySuccess("Successfully updated patient");
          navigate(`/patients/${patient.id}`);
        })
        .catch(() => {
          alert("Error updating patient");
        });
    } else {
      return addPatient(
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.streetAddress,
        data.city,
        data.state,
        data.zipCode,
        data.allergies,
        data.noKnownDrugAllergies,
        data.dateOfBirth,
        data.weight,
        data.height,
        data.notes,
        data.icd10Codes,
        data.preferredCommunication === "email",
        data.requireSignature,
        data.memberId,
        data.groupId
      )
        .then((patient) => {
          notifySuccess("Successfully added patient");
          navigate(`/patients/${patient.id}`);
        })
        .catch((e) => {
          alert(`Error submitting patient: ${e}`);
        });
    }
  };

  return (
    <DashboardPageBody
      title={patientId ? "Edit Patient" : "Add Patient"}
      backLink={patientId ? `/patients/${patientId}` : "/patients"}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.pageBody}>
          <div className={styles.pageBodyHeading}>
            {patientId ? "EDIT" : "ADD"} PATIENT DETAILS
          </div>
          <div className={styles.inputs}>
            <div className={styles.inputContainer}>
              <label htmlFor="firstName" className={styles.label}>
                First Name*
              </label>
              <input
                className={styles.input}
                id="firstName"
                {...register("firstName", { required: true })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="lastName" className={styles.label}>
                Last Name*
              </label>
              <input
                className={styles.input}
                id="lastName"
                {...register("lastName", { required: true })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="email" className={styles.label}>
                Email*
              </label>
              <input
                className={styles.input}
                id="email"
                {...register("email", { required: true })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="phone" className={styles.label}>
                Phone*
              </label>
              <input
                className={styles.input}
                id="phone"
                {...register("phone", { required: true })}
              />
            </div>
            <div className={`${styles.inputContainer} ${styles.inputLong}`}>
              <label htmlFor="streetAddress" className={styles.label}>
                Street Address*
              </label>
              <input
                className={styles.input}
                id="streetAddress"
                {...register("streetAddress", { required: true })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="city" className={styles.label}>
                City*
              </label>
              <input
                className={styles.input}
                id="city"
                {...register("city", { required: true })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="state" className={styles.label}>
                State*
              </label>
              <select
                className={styles.select}
                id="state"
                {...register("state", { required: true })}
              >
                {STATE_CODES.map((s) => (
                  <option value={s} key={s}>
                    {s}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="zipCode" className={styles.label}>
                Zip Code*
              </label>
              <input
                className={styles.input}
                id="zipCode"
                {...register("zipCode", { required: true })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="dateOfBirth">
                Date of Birth*
              </label>
              <input
                type="date"
                min="1900-01-01"
                max={new Date().toISOString().split("T")[0]}
                {...register("dateOfBirth", { required: true })}
                className={styles.input}
                id="dateOfBirth"
              />
              {errors.dateOfBirth && (
                <p className={styles.error}>Date of birth is required</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="memberId">
                Member ID
              </label>
              <input
                {...register("memberId", { required: false })}
                className={styles.select}
                id="memberId"
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="groupId">
                Group ID
              </label>
              <input
                {...register("groupId", { required: false })}
                className={styles.select}
                id="groupId"
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="height">
                Height (in)
              </label>
              <input
                {...register("height", {
                  required: false,
                  valueAsNumber: true,
                })}
                type="number"
                min={0}
                className={styles.select}
                id="height"
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="weight">
                Weight (lbs)
              </label>
              <input
                {...register("weight", {
                  required: false,
                  valueAsNumber: true,
                })}
                type="number"
                min={0}
                className={styles.select}
                id="weight"
              />
            </div>
            <div className={`${styles.inputContainer}`}>
              <label className={styles.label} htmlFor="allergies">
                Allergies
              </label>
              <input
                {...register("allergies", {
                  required: false,
                })}
                className={styles.input}
                id="allergies"
              />
            </div>
            <div className={`${styles.inputContainer}`}>
              <label className={styles.label} htmlFor="icd10Codes">
                ICD-10 Codes
              </label>
              <input
                {...register("icd10Codes", {
                  required: false,
                })}
                className={styles.input}
                id="icd10Codes"
              />
            </div>
            <div className={`${styles.inputContainer} ${styles.inputLong}`}>
              <label className={styles.label} htmlFor="notes">
                Notes
              </label>
              <input
                {...register("notes", {
                  required: false,
                })}
                className={styles.input}
                id="notes"
              />
            </div>
            <div
              className={`${styles.inputContainer} ${styles.inputLong} ${styles.radioContainer}`}
            >
              <label className={styles.communicationLabel}>
                Preferred communication:
              </label>
              <div className={styles.radioContainerInputs}>
                <div className={styles.radioContainerInput}>
                  <input
                    {...register("preferredCommunication")}
                    type="radio"
                    id="emailCommunication"
                    value={"email"}
                  />
                  <label htmlFor="emailCommunication">Email</label>
                </div>
                <div className={styles.radioContainerInput}>
                  <input
                    {...register("preferredCommunication")}
                    type="radio"
                    id="phoneCommunication"
                    value={"phone"}
                  />
                  <label htmlFor="phoneCommunication">Phone</label>
                </div>
              </div>
            </div>
            <div className={`${styles.inputContainer} ${styles.inputLong}`}>
              <input
                {...register("noKnownDrugAllergies")}
                className={styles.signatureCheckbox}
                type="checkbox"
                id="noKnownDrugAllergies"
              ></input>
              <label
                className={`${styles.label} ${styles.checkBoxLabel}`}
                htmlFor="noKnownDrugAllergies"
              >
                The patient has no known drug allergies
              </label>
            </div>
            <div className={`${styles.inputContainer} ${styles.inputLong}`}>
              <input
                {...register("requireSignature")}
                className={styles.signatureCheckbox}
                type="checkbox"
                id="requireSignature"
              ></input>
              <label
                className={`${styles.label} ${styles.checkBoxLabel}`}
                htmlFor="requireSignature"
              >
                Require signature upon delivery
              </label>
              {errors.noKnownDrugAllergies && (
                <p className={styles.error}>
                  Please acknowledge this patient has no known drug allergies.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.submitWrap}>
          <Link
            className={styles.cancelButton}
            to={patientId ? `/patients/${patientId}` : "/patients"}
          >
            Cancel
          </Link>
          <PrimaryButton type="submit" onClick={() => {}}>
            {patientId ? "Update Patient" : "Create Patient"}
          </PrimaryButton>
        </div>
      </form>
    </DashboardPageBody>
  );
}
