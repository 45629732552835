import { Prescription2, PrescriptionListItem } from "../../types/prescription";
import instance from "../../api/http_client";
import { Dispense } from "../../types/dispense";

export function getPrescriptions(patientId: string) {
  return instance
    .get(`/patients/${patientId}/prescriptions`)
    .then((response) => {
      return response.data.map(PrescriptionListItem.fromJson);
    });
}

export function getPrescription(patientId: string, prescriptionId: string) {
  return instance
    .get(`/patients/${patientId}/prescriptions/${prescriptionId}`)
    .then((response) => {
      return Prescription2.fromJson(response.data);
    });
}

export function submitPrescription(
  medicationId: string,
  patientId: string,
  daysSupply: number,
  quantity: number,
  dueDate: string,
  previouslyFilled: string,
  remainingRefills: number
): Promise<Prescription2> {
  return instance
    .post(`/patients/${patientId}/prescriptions/`, {
      medication_id: medicationId,
      days_supply: daysSupply,
      quantity: quantity,
      due_date: dueDate,
      filled_prior_to_transfer: previouslyFilled,
      remaining_refills: remainingRefills,
    })
    .then((response) => Prescription2.fromJson(response.data));
}

interface PrescriptionPrecheckResult {
  canFill: boolean;
  numFillsAvailable: number;
}

export function precheckPrescription(
  medicationId: string,
  patientId: string,
  daysSupply: number,
  quantity: number,
  dueDate: string,
  previouslyFilled: string,
  remainingRefills: number
): Promise<PrescriptionPrecheckResult> {
  return instance
    .post(`/patients/${patientId}/prescriptions/precheck/`, {
      medication_id: medicationId,
      days_supply: daysSupply,
      quantity: quantity,
      due_date: dueDate,
      filled_prior_to_transfer: previouslyFilled,
      remaining_refills: remainingRefills,
    })
    .then((response) => {
      if (response.status === 202) {
        return {
          canFill: false,
          numFillsAvailable: response.data["num_fills_available"],
        };
      }
      return {
        canFill: true,
        numFillsAvailable: response.data["num_fills_available"],
      };
    });
}

export function cancelPrescription(patientId: string, prescriptionId: string) {
  return instance.delete(
    `/patients/${patientId}/prescriptions/${prescriptionId}`
  );
}

export function editDispense(
  patientId: string,
  prescriptionId: string,
  dispenseId: string,
  dispenseDate: string
) {
  return instance
    .patch(
      `/patients/${patientId}/prescriptions/${prescriptionId}/dispenses/${dispenseId}/`,
      {
        dispense_date: dispenseDate,
      }
    )
    .then((res) => Dispense.fromJson(res.data));
}
