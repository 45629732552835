import { Dispense } from "../../../../../types/dispense";
import { getFedexTrackingUrl, getPrettyDate } from "../../../../../utils";
import styles from "./DispenseListItem.module.css";
import PencilColored from "../../../../../assets/Pencil-Colored.svg";
import { useState } from "react";
import { ApiStatus } from "../../../../../types/api_status";
import { toast } from "react-toastify";
import { useGlobalOverlay } from "../../../../../providers/GlobalOverlayProvider";
import Overlay from "../../../../../components/Overlay/Overlay";
import Card from "../../../../../components/Card/Card";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../../../../components/Button2/PrimaryButton";
import SecondaryButton from "../../../../../components/Button2/SecondaryButton";
import { editDispense } from "../../../api";

export default function DispenseListItem(props: {
  dispense: Dispense;
  prescriptionId: string;
  patientId: string;
  //   onDelete: () => void;
}) {
  const [dispense, setDispense] = useState(props.dispense);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ date: string }>({
    defaultValues: {
      date: props.dispense.dispenseDate.toISOString().substring(0, 10),
    },
  });

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const [editDispenseStatus, setEditDispenseStatus] = useState(
    ApiStatus.INITIAL
  );

  const handleUpdate = (data: { date: string }) => {
    setEditDispenseStatus(ApiStatus.LOADING);
    hideGlobalOverlay();
    return editDispense(
      props.patientId,
      props.prescriptionId,
      props.dispense.pk,
      data.date
    )
      .then((dispense) => {
        setEditDispenseStatus(ApiStatus.SUCCESS);
        toast.success("Updated dispense");
        setDispense(dispense);
      })
      .catch(() => {
        setEditDispenseStatus(ApiStatus.FAILURE);
        toast.error("Error updating dispense");
      });
  };

  return (
    <tr>
      <td>{getPrettyDate(dispense.dispenseDate)}</td>
      <td>{dispense.shippingStatus}</td>
      <td>
        {dispense.signedConsentForm ? (
          <a
            href={dispense.signedConsentForm}
            className={styles.tableLink}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
        ) : (
          <p>Unsigned</p>
        )}
      </td>
      <td>
        {dispense.trackingNumber === "" ? (
          "N/A"
        ) : (
          <a
            href={getFedexTrackingUrl(dispense.trackingNumber)}
            className={styles.tableLink}
            target="_blank"
            rel="noreferrer"
          >
            {dispense.trackingNumber}
          </a>
        )}
      </td>
      <td>
        <div className={styles.dispenseActions}>
          {editDispenseStatus === ApiStatus.LOADING ? (
            <div className={styles.loader}></div>
          ) : (
            <button
              title="Edit"
              className={styles.iconButton}
              onClick={() => {
                setGlobalOverlay(
                  <Overlay>
                    <Card>
                      <form onSubmit={handleSubmit(handleUpdate)}>
                        <div className={styles.editDispenseDateCard}>
                          <h3>Edit dispense date</h3>
                          <p className={styles.editDispenseDateDescription}>
                            Please note that new consent forms will have to be
                            signed by the patient.
                          </p>
                          <div className={styles.inputContainer}>
                            <label
                              className={styles.label}
                              htmlFor="dispenseDate"
                            >
                              Dispense date
                            </label>
                            <input
                              type="date"
                              {...register("date", { required: true })}
                              className={styles.input}
                              id="dispenseDate"
                            />
                            {errors.date && (
                              <p className={styles.error}>
                                Dispense date is required
                              </p>
                            )}
                          </div>
                          <div className={styles.editDispenseDateActions}>
                            <SecondaryButton
                              onClick={hideGlobalOverlay}
                              type="button"
                            >
                              Cancel
                            </SecondaryButton>
                            <PrimaryButton type="submit">Update</PrimaryButton>
                          </div>
                        </div>
                      </form>
                    </Card>
                  </Overlay>
                );
              }}
            >
              <img
                src={PencilColored}
                title="Edit dispense"
                alt="Edit dispense"
              ></img>
            </button>
          )}
          {/* <button className={styles.iconButton} onClick={() => {}}>
            <img
              src={Trash}
              title="Delete dispense"
              alt="Delete dispense"
            ></img>
          </button> */}
        </div>
      </td>
    </tr>
  );
}
